import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import theme from "../consts/theme"

const SC = {}

const PoliticaDePrivacidade = ({ data }) => {
  const content = data.allGhostPage.edges[0].node

  return (
    <Layout>
      <SEO title="Política de Privacidade" />
      <Container
        isFlex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        containerWidth={theme.sizes.MEDIUM_CONTAINER}
      >
        <SC.Heading>Política de Privacidade</SC.Heading>
        <SC.Content dangerouslySetInnerHTML={{ __html: content.html }} />
      </Container>
    </Layout>
  )
}

SC.Heading = styled.h1`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-size: 32px;
  color: #000;
  margin-top: 80px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-top: 100px;
  }
`

SC.Content = styled.div`
  font-size: 18px;
  line-height: 1.78;
  margin-bottom: 120px;
  margin-top: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 0 25px;
    margin-top: 30px;
  }

  h2,
  h3 {
    font-weight: 500;
  }

  h2 {
    color: #000;
    font-size: 36px;
    margin-top: 60px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      font-size: 24px;
    }
  }

  h3 {
    color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      font-size: 20px;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
    text-decoration-thickness: 2.8px;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
      text-shadow: 0 0 1px ${({ theme }) => theme.colors.PRIMARY_PURPLE};
    }
  }
`

export const pageQuery = graphql`
  {
    allGhostPage(filter: { title: { eq: "Política de Privacidade" } }) {
      edges {
        node {
          html
        }
      }
    }
  }
`

export default PoliticaDePrivacidade
